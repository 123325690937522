import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { ExpansionPanel } from 'gatsby-theme-apollo-docs/src/components/expansion-panel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ExpansionPanel title="How to obtain a graph API key" mdxType="ExpansionPanel">
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`API keys are secret credentials.`}</strong>{` Never share them outside your organization or commit them to version control. Delete and replace API keys that you believe are compromised.`}</p>
      </blockquote>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Go to `}<a parentName="p" {...{
              "href": "https://studio.apollographql.com/"
            }}>{`studio.apollographql.com`}</a>{` and click the graph you want to obtain an API key for.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li"><strong parentName="p">{`If a "Publish your Schema" dialog appears`}</strong>{`, select the `}<strong parentName="p">{`From Apollo Server`}</strong>{` tab. Copy the value that appears after `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`APOLLO_KEY=`}</code>{` in the instructions (it begins with `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`service:`}</code>{`), and you're all set.`}</p>
          <p parentName="li"><strong parentName="p">{`Otherwise`}</strong>{`, proceed to the next step.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Open your graph's Settings page and scroll down to the API Keys section. Either copy an existing key or click `}<strong parentName="p">{`Create New Key`}</strong>{`.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Optionally click the `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`…`}</code>{` button to the right of the API key to give it a name, such as `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`Production`}</code>{`. This helps you keep track of each API key's use.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Copy the key's value.`}</p>
        </li>
      </ol>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import { Button } from '@apollo/space-kit/Button';
import { Link } from 'gatsby';
import { ExpansionPanel } from 'gatsby-theme-apollo-docs';
import { VideoFrame } from 'gatsby-theme-apollo-docs';
import * as React from 'react';
export default {
  Button,
  Link,
  ExpansionPanel,
  VideoFrame,
  React
};